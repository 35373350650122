define(['app'], function(app) {

  

  var styleguide = function() {
    var self = this;

    var _config = {
      colorBlockSelector: '[data-js-element=colorBlock]',
      messagingColorBlockSelector: '[data-js-element=messagingColorBlock]',
      textStyleSelector: '[data-js-element=textStyle]',
      secureUrlSelector: 'data-secure-url',
      siteCodeSelector: 'data-site-code',
      isMobileSelector: 'data-is-mobile',
      colorSelector: 'data-color',
      primaryFontNameSelector: '[data-font-family-name=primary]',
      secondaryFontNameSelector: '[data-font-family-name=secondary]',
      borderStylesContainerSelector: '[data-border-styles]',
      fontFamilyElementSelector: '[data-property=fontFamily]',
      fontWeightElementSelector: '[data-property=fontWeight]',
      fontSizeElementSelector: '[data-property=fontSize]',
      letterSpacingPxSelector: '[data-property=letterSpacingPx]',
      lineHeightPxSelector: '[data-property=lineHeightPx]',
      lineHeightCodeSelector: '[data-property=lineHeightCode]',
      borderWidthSelector: '[data-border-width]',
      borderStyleSelector: '[data-border-style]',
      borderRadiusSelector: '[data-border-radius]',
      toggleGridSelector: '[data-js-element=toggleGrid]',
      gridClass: 'styleguide_showGrid',
      propertyValues: {
        fontFamily: 'font-family',
        fontSize: 'font-size',
        fontWeight: 'font-weight',
        lineHeight: 'line-height',
        letterSpacing: 'letter-spacing',
        backgroundColor: 'background-color',
        borderWidth: 'border-width',
        borderStyle: 'border-style',
        borderRadius: 'border-radius'
      }
    };

    var _init = function(element) {
      self.element = element;
      self.colorBlocks = self.element.querySelectorAll(self.config.colorBlockSelector);
      self.messagingColorBlocks = self.element.querySelectorAll(self.config.messagingColorBlockSelector);
      self.textStyles = self.element.querySelectorAll(self.config.textStyleSelector);
      self.secureUrl = app.element.getAttribute(self.config.secureUrlSelector, self.element);
      self.siteCode = app.element.getAttribute(self.config.siteCodeSelector, self.element);
      self.primaryFontName = self.element.querySelector(self.config.primaryFontNameSelector);
      self.secondaryFontName = self.element.querySelector(self.config.secondaryFontNameSelector);
      self.borderStylesContainer = self.element.querySelector(self.config.borderStylesContainerSelector);
      self.isMobile = app.element.getAttribute(self.config.isMobileSelector, self.element) === 'true';
      self.toggleGridButton = self.element.querySelector(self.config.toggleGridSelector);

      self.generateHexes();
      self.generateTextStyles();
      self.generateFontFamilies();
      self.generateBorderStyles();
      self.addToggleListener();

      return self;
    };

    var _addToggleListener = function() {
      self.toggleGridButton.addEventListener('click', self.toggleGrid);
    };

    var _toggleGrid = function() {
      if (app.element.hasClass(self.config.gridClass, self.element)) {
        app.element.removeClass(self.config.gridClass, self.element);
      } else {
        app.element.addClass(self.config.gridClass, self.element);
      }
    };

    var _generateHexFromColorBlock = function(colorBlock) {
      var colorType = app.element.getAttribute(self.config.colorSelector, colorBlock);
      var hexElement = self.element.querySelector('[data-hex="' + colorType + '"]');
      var hex = self.rgb2hex(
        window.getComputedStyle(colorBlock).getPropertyValue(self.config.propertyValues.backgroundColor));

      self.insertHex(hexElement, hex);
    };

    var _insertHex = function(hexElement, hex) {
      hexElement.innerText = hex;
    };

    var _generateHexes = function() {
      for (var i = 0, l = self.colorBlocks.length; i < l; i++) {
        self.generateHexFromColorBlock(self.colorBlocks[i]);
      }

      for (var j = 0, m = self.messagingColorBlocks.length; j < m; j++) {
        self.generateHexFromColorBlock(self.messagingColorBlocks[j]);
      }
    };

    var _generateTextStyles = function() {
      for (var i = 0, l = self.textStyles.length; i < l; i++) {

        var thisTextStyle = self.textStyles[i];

        self.doFontFamily(thisTextStyle);
        self.doFontWeight(thisTextStyle);
        self.doFontSize(thisTextStyle);
        self.doLineHeightPx(thisTextStyle);
        self.doLineHeightCode(thisTextStyle);
        self.doLetterSpacing(thisTextStyle);
      }
    };

    var _doFontFamily = function(textStyle) {
      var fontFamilyElement = textStyle.querySelector(self.config.fontFamilyElementSelector);
      var fontFamily = window.getComputedStyle(textStyle).getPropertyValue(self.config.propertyValues.fontFamily);

      if (fontFamilyElement) {
        fontFamilyElement.innerText = fontFamily.split(',')[0];
      }
    };

    var _doFontWeight = function(textStyle) {
      var fontWeightElement = textStyle.querySelector(self.config.fontWeightElementSelector);

      if (fontWeightElement) {
        fontWeightElement.innerText = window.getComputedStyle(textStyle).getPropertyValue(self.config.propertyValues.fontWeight);
      }
    };

    var _doFontSize = function(textStyle) {
      var fontSizeElement = textStyle.querySelector(self.config.fontSizeElementSelector);

      if (fontSizeElement) {
        fontSizeElement.innerText = window.getComputedStyle(textStyle).getPropertyValue(self.config.propertyValues.fontSize);
      }
    };

    var _doLineHeightPx = function(textStyle) {
      var lineHeightPxElement = textStyle.querySelector(self.config.lineHeightPxSelector);

      if (lineHeightPxElement) {
        lineHeightPxElement.innerText = window.getComputedStyle(textStyle).getPropertyValue(self.config.propertyValues.lineHeight);
      }
    };

    var _doLineHeightCode = function(textStyle) {
      var lineHeightCodeElement = textStyle.querySelector(self.config.lineHeightCodeSelector);
      var lineHeight = parseInt(window.getComputedStyle(textStyle).getPropertyValue(self.config.propertyValues.lineHeight),
        10);
      var fontSize = parseInt(window.getComputedStyle(textStyle).getPropertyValue(self.config.propertyValues.fontSize), 10);

      if (isNaN(lineHeight) || isNaN(fontSize)) {
        return;
      }

      var lineHeightCode = lineHeight / fontSize;

      if (lineHeightCodeElement) {
        lineHeightCodeElement.innerText = lineHeightCode.toFixed(1);
      }
    };

    var _doLetterSpacing = function(textStyle) {
      var letterSpacingPxElement = textStyle.querySelector(self.config.letterSpacingPxSelector);

      if (letterSpacingPxElement) {
        letterSpacingPxElement.innerText = window.getComputedStyle(textStyle).getPropertyValue(self.config.propertyValues.letterSpacing);
      }
    };

    var _generateFontFamilies = function() {
      var primaryName = window.getComputedStyle(self.primaryFontName).getPropertyValue(self.config.propertyValues.fontFamily);
      self.primaryFontName.innerText = primaryName.replace(/"/g, '');

      var secondaryName = window.getComputedStyle(self.secondaryFontName).getPropertyValue(self.config.propertyValues.fontFamily);
      self.secondaryFontName.innerText = secondaryName.replace(/"/g, '');
    };

    var _getDeviceString = function() {
      return self.isMobile ? 'mobile' : 'desktop';
    };

    var _generateBorderStyles = function() {
      var borderWidthElement = self.borderStylesContainer.querySelector(self.config.borderWidthSelector);

      if (borderWidthElement) {
        borderWidthElement.innerText = window.getComputedStyle(self.borderStylesContainer).getPropertyValue(self.config.propertyValues.borderWidth);
      }

      var borderStyleElement = self.borderStylesContainer.querySelector(self.config.borderStyleSelector);

      if (borderStyleElement) {
        borderStyleElement.innerText = window.getComputedStyle(self.borderStylesContainer).getPropertyValue(self.config.propertyValues.borderStyle);
      }

      var borderRadiusElement = self.borderStylesContainer.querySelector(self.config.borderRadiusSelector);

      if (borderRadiusElement) {
        borderRadiusElement.innerText = window.getComputedStyle(self.borderStylesContainer).getPropertyValue(self.config.propertyValues.borderRadius);
      }
    };

    var _rgb2hex = function(rgb) {
      rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

      function hex(x) {
        return ('0' + parseInt(x).toString(16)).slice(-2);
      }

      return '#' + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
    };

    self.config = _config;
    self.init = _init;

    self.addToggleListener = _addToggleListener;
    self.toggleGrid = _toggleGrid;
    self.generateBorderStyles = _generateBorderStyles;
    self.generateFontFamilies = _generateFontFamilies;
    self.generateHexes = _generateHexes;
    self.generateHexFromColorBlock = _generateHexFromColorBlock;
    self.generateTextStyles = _generateTextStyles;

    self.rgb2hex = _rgb2hex;
    self.insertHex = _insertHex;

    self.doFontFamily = _doFontFamily;
    self.doLetterSpacing = _doLetterSpacing;
    self.doLineHeightCode = _doLineHeightCode;
    self.doLineHeightPx = _doLineHeightPx;
    self.doFontSize = _doFontSize;
    self.doFontWeight = _doFontWeight;

    self.getDeviceString = _getDeviceString;
  };

  return styleguide;
});
